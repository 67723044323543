<script>
  export let scoped;

  import { fetchJSON } from "../utils.js";
  import { loadingCount } from "../stores.js";

  import Error from "../components/Error.svelte";
  import Header from "../components/Header.svelte";

  const { loading: parentLoading } = scoped;

  let title, content;
  const loading = Promise.all([
    parentLoading,
    fetchJSON("/api/v1/pages/more").then((more) => {
      var textarea = document.createElement("textarea");
      textarea.innerHTML = more.title.rendered;
      title = textarea.value;
      if (title.startsWith("Private:"))
        title = title.slice("Private:".length).trimStart();
      content = more.content.rendered;
    }),
  ]);

  loadingCount.update((n) => n + 1);
  loading.finally(() => loadingCount.update((n) => n - 1));
</script>

{#await loading}
  <!-- Loading -->
{:then}
  <Header {title} />

  <main>
    {@html content}
  </main>
{:catch error}
  <Error {error} />
{/await}

<style>
  /* add styles to the main element like so, as required */
  main :global(p) {
    margin-bottom: 0.5em;
  }

  main :global(ul) {
    margin: 0.5em 1rem;
  }
</style>
